import moment from 'moment';
import * as moment2 from 'moment-timezone';
import { getLeadTime } from './editModuleFunctions';
import { HOW_TO_GET_YOUR_ORDER } from '../../../constants/cartConstants';

const useFulfillmentHooks = () => {
    const isIn24Hrs = (selectedStartDate, currentOffSet) => {
        let formattedCurrentOffSet = moment(currentOffSet);
        let formattedSelectedStartDate = moment(selectedStartDate);
        formattedSelectedStartDate = formattedSelectedStartDate.set({
            hour: formattedCurrentOffSet.get('hour'),
            minute: formattedCurrentOffSet.get('minute'),
            second: formattedCurrentOffSet.get('second'),
            millisecond: 0
        });
        const timeDiff = formattedSelectedStartDate.diff(formattedCurrentOffSet, 'seconds');
        if (timeDiff <= HOW_TO_GET_YOUR_ORDER.ONE_DAY_DIFFERENCE_IN_SECONDS) {
            return true;
        } else return false;
    };

    const computeLeadWithOffset = (currentOffSet) => {
        // Get the lead time in hours based on the current offset
        const leadTimeInHours = getLeadTime(currentOffSet);
        const formattedCurrentOffSet = moment(currentOffSet);
        // Add lead time with currentOffSet time
        const updatedCurrentOffSet = formattedCurrentOffSet?.clone()?.add(leadTimeInHours, 'hours');
        return updatedCurrentOffSet;
    };

    const isValidSlotInLast24Hours = (lastStartDate, currentOffSet) => {
        const formattedLastStartDate = moment(lastStartDate);
        const currentOffSetWithLead = computeLeadWithOffset(currentOffSet);
        // start date last slot should be after the currentOffSetWithLead
        const isValid = formattedLastStartDate?.isAfter(currentOffSetWithLead);
        return isValid;
    };

    const validatedPickupSlot = (startDate, lastPickupTimeSlot, currentOffSet, timeZoneID) => {
        const localDate = new Date(startDate);
        const date = moment(localDate)?.format('YYYY-MM-DD');
        const combinedDateTime = `${date} ${lastPickupTimeSlot}`;
        // Create moment instance with specific format and timezone
        const pickupMoment = moment2?.tz(combinedDateTime, 'YYYY-MM-DD hh:mm A', timeZoneID);
        const currentOffSetWithLead = computeLeadWithOffset(currentOffSet);
        //  Check if pickupMoment is after updatedCurrentOffSet
        const isAfter = pickupMoment?.isAfter(currentOffSetWithLead);
        console.log('isAfter', isAfter)
        console.log('date', date);
        console.log('combinedDateTime', combinedDateTime);
        console.log('pickupMoment', pickupMoment);
        console.log('pickup Time', pickupMoment?.format('dddd, MMMM D, YYYY h:mm A'));
        console.log('updatedCurrentOffSet Offset new', currentOffSetWithLead?.format('dddd, MMMM D, YYYY h:mm A'));
        return isAfter;
    };

    const isPCfulfillmentVoid = (selectedStartDate, currentOffSet, storesData) => {
        if (
            isIn24Hrs(selectedStartDate, currentOffSet) &&
            !storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.isOpenOnDateOut
        ) {
            return true;
        } else if (
            isIn24Hrs(selectedStartDate, currentOffSet) &&
            storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.isLastResortBranch === true
        ) {
            return true;
        } else if (
            isIn24Hrs(selectedStartDate, currentOffSet) &&
            storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.fulfillmentPercent > 0 &&
            storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.fulfillmentPercent < 1 &&
            !storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.isLastResortBranch === true
        ) {
            return true;
        } else if (
            !isIn24Hrs(selectedStartDate, currentOffSet) &&
            !storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.isOpenOnDateOut
        ) {
            return true;
        } else return false;
    };
    return { isIn24Hrs, isPCfulfillmentVoid, isValidSlotInLast24Hours, validatedPickupSlot };
};
export default useFulfillmentHooks;
