import React, { memo, Suspense } from 'react';

const LazySearchBox = React.lazy(() => import(/* webpackChunkName: "cmp-SearchBox" */ '../srp/SearchBox'));
const LazySearchTypeAhead = React.lazy(() =>
    import(/* webpackChunkName: "cmp-SearchTypeAhead" */ '../Search').then(module => ({ default: module.SearchTypeAhead }))
);
const Fallback = memo(() => <span>loading</span>);
const GlobalSearch = () => {
    return (
        <Suspense fallback={<>Fallback</>}>
            {window.location.pathname.includes('/search/') ? <LazySearchBox /> : <LazySearchTypeAhead />}
        </Suspense>
    );
};

export default GlobalSearch;
